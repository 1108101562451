import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import moment from 'moment';
// Elements
import BlogSidebar from '../elements/blog/blog-sidebar';

// Images
import DefaultPic1 from "../../images/blog/default/pic1.jpg"
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64';
import utf8 from "utf8";

class BlogDetailsSidebar extends Component {
	
	render(){
		const { _item } = this.props.location.state;


		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Blog Detay</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Blog Detay</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-8 col-xl-8 m-md-b50 wow fadeInUp" data-wow-delay="0.2s">
										{/* <!-- blog start --> */}
										<div className="blog-lg blog-single">
											<div className="action-box blog-lg">
												<img style={{height:300}} src={"https://megasubdomain.stechomeyazilim.info/"+_item.BlogImg} alt=""/>
											</div>
											<div className="info-bx">
											
												<div className="ttr-post-title">
													<h4 className="post-title">{_item.BlogTitle}</h4>
												</div>
												<div className="ttr-post-text">
												<ReactHtml style={{color:'white'}} html={(base64.decode(utf8.decode(_item.BlogsExplanation)))} />
						
													
														</div>
												<div className="ttr-divider bg-gray"><i className="icon-dot c-square"></i></div>
												
											</div>
										</div>
									
									
										{/* <!-- blog END --> */}
									</div>
									<div className="col-md-12 col-lg-4 col-xl-4 sticky-top wow fadeInUp" data-wow-delay="0.4s">
										<BlogSidebar />
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default BlogDetailsSidebar;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';


// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import Slider from '../elements/slider/slider1';
import ServiceSlider1 from '../elements/services/service-slider-1';
import ServiceSlider2 from '../elements/services/service-slider-2';
import About from '../elements/about/about1';
import NumberBox from '../elements/number-box/number-box';
import Testimonial from '../elements/testimonial/testimonial1';
import what from "../../images/what.png"
import Logo from "../../images/logo.png"
// Images
import ServicesPic1 from "../../images/our-services/pic1.jpg"
import ServicesPic2 from "../../images/our-services/pic2.jpg"
import BlogGridPic1 from "../../images/blog/grid/pic1.jpg"
import BlogGridPic2 from "../../images/blog/grid/pic2.jpg"
import BlogGridPic3 from "../../images/blog/grid/pic3.jpg"
import BlogGridPic4 from "../../images/blog/grid/pic4.jpg"


class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data2: [],
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',

		};
	}

	componentDidMount() {
		const apiUrl = "https://getjob.stechomeyazilim.info:5101/getSSSEzgican/select/";

		fetch(apiUrl)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ data });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactEzgican/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});
		const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getBlogEzgican/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render() {
		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<Slider />

					{/* <!-- Main Slider --> */}
					<div className="content-block" id="content-area">


						<div className="section-area process-area">
							<div className="container">
								<ServiceSlider1 />
							</div>
						</div>

						<div className="section-area section-sp3 bg-white">
							<div className="container">
								<About />
							</div>
						</div>




						<div className="section-area bg-white section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-4 col-md-12">
										<div className="heading-bx m-b20 text-center  text-lg-left m-md-b30">
											<h6 className="title-ext m-b0">SSS</h6>
											<h2 className="title-head m-b0">Sıkça Sorulan Sorular</h2>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Bizimle ilgili merak ettikleriniz ve daha fazlasına buradan ulaşabilirsiniz.</p>
											<Link to="get-in-touch" className="btn">Daha Fazlası</Link>
										</div>
									</div>

									{this.state.data.slice(0, 2).map((item) => (

										<div className="col-lg-4 col-md-6 col-sm-6">
											<div className="service-box text-center m-xs-b30">
												<div className="service-media">
													<img src={Logo} alt="" />
													<div className="service-inner">
														<Link to="#" className="btn white">View All</Link>
													</div>
												</div>
												<div className="service-info">
													<h4 className="title">{item.SSSTitle}</h4>
													<p>{item.SSSDesc} </p>
												</div>
											</div>
										</div>

									))}

								</div>
							</div>
						</div>

						<div className="section-area bg-primary section-sp2 client-appoint-box">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="heading-bx text-white">
											<h6 className="title-ext m-b0">EZGİCAN KURUYEMİŞ </h6>
											<h2 className="title-head m-b0">Bizimle İletişim Kurabilirsiniz</h2>
											<div className="ttr-separator sepimg2"></div>
										</div>
										<div className="swiper-container client-area-box">
											<Testimonial />
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<form className="ajax-form" action="script/contact.php">
											<div className="apoint-btn-box">bİze yazın</div>
											<div className="ajax-message"></div>
											<div className="heading-bx text-white">
												<h6 className="title-ext m-b0">İletİşİm</h6>
												<h2 className="title-head m-b0">Bize Ulaşın</h2>
												<div className="ttr-separator sepimg2"></div>
											</div>
											<div className="row placeani appoint-bx1">
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="name" type="text" required className="form-control valid-character" placeholder="İsim" />
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="email" type="email" className="form-control" required placeholder="Email Adresiniz" />
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="phone" type="text" required className="form-control int-value" placeholder="Telefon Numaranız" />
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<div className="input-group input-bx">
															<textarea name="message" rows="4" className="form-control" placeholder="Mesajınız" required ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<button name="submit" type="submit" value="Submit" className="btn button-md"> Mesaj Gönder</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>

						<div className="section-area bg-white section-sp1 blog-area">
							<div className="container">
								<div className="row heading-bx align-items-end">
									<div className="col-lg-8 col-md-9">
										<h6 className="title-ext m-b0">BLOGLAR</h6>
										<h2 className="title-head m-b0">Ezgican Kuruyemiş Blog Yazıları</h2>
										<div className="ttr-separator sepimg"></div>
									</div>
									<div className="col-lg-4 col-md-3">
										<Link to="blog-classic" className="btn float-right">Tüm Bloglar</Link>
									</div>
								</div>
								<div className="row">

									{this.state.data3.slice(0, 1).map((item) => (
										<div className="col-md-6">
											<div className="recent-news">
												<div className="action-box">
													<img src={this.state.imageUrl + item.BlogImg} alt="" />
												</div>
												<div className="info-bx">
												
													<h3 className="post-title"><Link to={{ pathname: '/blog-details-sidebar', state: { _item: item } }}>{item.BlogTitle}</Link></h3>
												</div>
											</div>
										</div>
									))}
									<div className="col-md-6">
										<div className="side-post">



											{this.state.data3.slice(0, 3).map((item) => (

												<div className="blog-post blog-md clearfix wow fadeInUp" data-wow-delay="0.2s">
													<div className="ttr-post-media">
														<Link to={{ pathname: '/blog-details-sidebar', state: { _item: item } }}>	<img src={this.state.imageUrl + item.BlogImg} alt="" /></Link>
													</div>
													<div className="ttr-post-info">
														
														<h4 className="post-title"><Link to={{ pathname: '/blog-details-sidebar', state: { _item: item } }}>{item.BlogTitle}</Link></h4>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>

				<Footer1 />

				<div style={{ position: 'fixed', bottom: '80px', right: '20px', zIndex: '999' }}>
				{this.state.data2.slice(0, 3).map((item) => (
					<a href="https://api.whatsapp.com/send?phone=${item.ContactNumber}" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block',  color: 'white', padding: '10px', borderRadius: '50%', transition: 'background-color 0.3s ease' }}>
				
					<img style={{width:40}} src={what} alt="" />
					</a>
							))}
				</div>
			</>
		);
	}
}

export default Index;
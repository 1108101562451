import React, { Component } from 'react';
import Slider from "react-slick";
import Image1 from "../../../images/slider/slide1.jpg";
import Image2 from "../../../images/slider/slide2.jpg";

class Slider1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], // Initialize data as an empty array
      imageUrl :'https://megasubdomain.stechomeyazilim.info/',

    };
  }

  componentDidMount() {
    const apiUrl = "https://getjob.stechomeyazilim.info:5101/getSlidersEzgican/select/";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data: data }); // Set the fetched data in the state
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    // Access data from state using this.state.data
    return (
      <>
        <Slider {...settings} className="tt-slider slider-one slider-sp0">
          {this.state.data.map((item) => (
            <div className="slider-item" key={item.id}> {/* Add a unique key */}
              <div className="slider-thumb">
   
                <img  src={this.state.imageUrl+item.ImgUrl} alt=""/>
              </div>
              <div className="slider-content text-white text-center">
                <div className="container">
                  <div className="content-inner">
                    <h6 className="sub-title">{item.SliderTitleTwo}</h6>
                    <h2 className="title">{item.SliderTitle}</h2>
                    <a className="btn m-lr10" href="/get-in-touch">
                Bize Ulaşın
                    </a>
                    <a className="btn m-lr10 white" href="/contact-1">
              İletişim
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}

export default Slider1;

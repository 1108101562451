import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import Logo from "../../../images/logo2.png"
import Logo2 from "../../../images/logo_3.png"
class Header1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactEzgican/select/";

    fetch(apiUrl2)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });

    // Cart Dropdown 
    var cartBtn = document.getElementById("cartBtn");
    var cartDropdown = document.querySelector(".cart-dropdown");

    cartBtn.addEventListener('click', function () {
      cartDropdown.classList.toggle("show");
    });

    // Search Form Popup
    var searchBtn = document.getElementById("quik-search-btn");
    var searchForm = document.querySelector(".nav-search-bar");
    var closeBtn = document.getElementById("search-remove");

    searchBtn.addEventListener('click', function () {
      searchForm.classList.add("show");
    });

    closeBtn.addEventListener('click', function () {
      searchForm.classList.remove("show");
    });

    // Mobile Menu sidebar function
    var btn = document.querySelector('.menuicon');
    var nav = document.querySelector('.menu-links');

    function toggleFunc() {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    }

    btn.addEventListener('click', toggleFunc);

    // Mobile Submenu open close function
    var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener('click', function () { menuClick(this) });
    }

    function menuClick(current) {
      const active = current.classList.contains("open")
      navMenu.forEach(el => el.classList.remove('open'));

      if (active) {
        current.classList.remove('open')
        console.log("active")
      } else {
        current.classList.add('open');
        console.log("close")
      }
    }
  }

  render() {
    return (
      <>
        <header className="header rs-nav">
          <div className="top-bar">
            <div className="container">
              <div className="row d-flex justify-content-between">
                <div className="topbar-left">
                  {this.state.data.map((item) => (
                    <ul>
                      <li><a href={"tel:" + item.ContactNumber}><i className="la la-phone"></i>Bizi Ara {item.ContactNumber}</a></li>
                      <li><a href={"mailto:" + item.ContactEmail}><i className="las la-envelope-open"></i>{item.ContactEmail}</a></li>
                    </ul>
                  ))}
                </div>
                <div className="topbar-right">
                  <ul>
                    <li><Link to="get-in-touch">Bize Ulaşın</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Sticky enabled={true} className="sticky-header navbar-expand-lg">
            <div className="menu-bar clearfix">
              <div className="container clearfix">
                <div className="menu-logo">
                  <Link className="d-flex" to="/"><img src={Logo} alt="" /><img  src={Logo2} alt="" /></Link>
                </div>
                <button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="secondary-menu">
                  <div className="secondary-inner">
                    <ul>
                      <li className="search-btn">
                        <button id="quik-search-btn" type="button" className="btn-link"></button>
                      </li>
                      <li className="d-none d-sm-block">
                        <Link to="get-in-touch" className="btn">İletişim</Link>
                      </li>
                      <li>
                        <Link to="#" id="cartBtn"></Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
                  <div className="menu-logo">
                    <Link to="/"><img src={Logo} alt="" /></Link>
                  </div>
                  <ul className="nav navbar-nav">
                    <li className={window.location.pathname === '/' ? 'active' : ''}>
                      <Link to="/">Anasayfa</Link>
                    </li>
                    <li className={window.location.pathname === '/faq-1' ? 'active' : ''}>
                      <Link to="/faq-1">SSS</Link>
                    </li>
                    <li className={window.location.pathname === '/about-1' ? 'active' : ''}>
                      <Link to="/about-1">Hakkımızda</Link>
                    </li>
                    <li className={window.location.pathname === '/blog-classic' ? 'active' : ''}>
                      <Link to="/blog-classic">Blog</Link>
                    </li>
                  </ul>
                  {this.state.data.map((item) => (
                    <div className="nav-social-link">
                      <Link to={item.FacebookAdd}><i className="fa fa-facebook"></i></Link>
                      <Link to={item.TwitterAdd}><i className="fa fa-twitter"></i></Link>
                      <Link to={item.InstagramAdd}><i className="fa fa-instagram"></i></Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Sticky>
          <div className="nav-search-bar">
            <form action="#">
              <input name="search" type="text" className="form-control" placeholder="Type to search" />
              <span><i className="ti-search"></i></span>
            </form>
            <span id="search-remove"><i className="ti-close"></i></span>
          </div>

        </header>
      </>
    );
  }
}

export default Header1;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card } from 'react-bootstrap';
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import ClientLogo from '../elements/client-logo/client-logo-1';
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';

class Faq2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data2: [],
      data3: [],
      imageUrl :'https://ezgicanapi.stechomeyazilim.info/Uploads/',
    };
  }

  componentDidMount() {
    const apiUrl = "https://getjob.stechomeyazilim.info:5101/getSSSEzgican/select/";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ data });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });

    const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getContactEzgican/select/";

    fetch(apiUrl2)
      .then((response) => response.json())
      .then((data2) => {
        this.setState({ data2 });
      })
      .catch((error) => {
        console.error("API'den veri çekme hatası:", error);
      });

      const apiUrl3 = "https://getjob.stechomeyazilim.info:5101/getAboutEzgican/select/";

      fetch(apiUrl3)
        .then((response) => response.json())
        .then((data3) => {
          this.setState({ data3 });
        })
        .catch((error) => {
          console.error("API'den veri çekme hatası:", error);
        });
  
  }

  render() {
    const { data, data2 } = this.state;

    return (
      <>
        <Header1 />

        <div className="page-content bg-white">

        {this.state.data3.map((item) => (
        	<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + this.state.imageUrl + item.SSSBannerImage + ")" }}>
			    <div className="container">
              <div className="page-banner-entry">
                <h1 className="text-white">SSS</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                    <li>SSS</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          		))}

          <div className="content-block" id="content-area">
            <div className="section-area section-sp2">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="heading-bx">
                      <h6 className="title-ext m-b0">CEVAP BULUN</h6>
                      <h3 className="title-head m-b0">Sıkça Sorulan Sorular</h3>
                      <div className="ttr-separator sepimg"></div>
                        </div>
                    <Accordion className="ttr-accordion gray" defaultActiveKey="0">
                      {data.map((item) => (
                        <Card key={item.ID}>
                          <Accordion.Toggle as={Card.Header} eventKey={item.ID.toString()}>
                            <Link to="#">{item.SSSTitle}</Link>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={item.ID.toString()}>
                            <Card.Body>{item.SSSDesc}</Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      ))}
                    </Accordion>
                  </div>
                  <div className="col-lg-4 col-md-5">
                    <aside className="sticky-top left-border-1">
                      <div className="widget">
                        <h6 className="widget-title">Dİğer Sayfalar</h6>
                        <ul className="service-list style-2">
                          <li><Link to="about-1">Hakkımızda <i className="las la-question-circle"></i></Link></li>
                          <li className="active"><Link to="faq-1">SSS<i className="las la-quote-left"></i></Link></li>
                        </ul>
                        <Link to="get-in-touch" className="btn btn-block m-t20">İletişim</Link>
                      </div>
                      <div className="widget">
                        <h6 className="widget-title">Bİze Ulaşın</h6>
                        {data2.map((item) => (
                          <ul className="contact-infolist" key={item.ID}>
                            <li>
                              <img src={Icon1} alt="" className="mCS_img_loaded" />
                              <h6 className="contact-title">İletişim Numarası</h6>
                              <p>{item.ContactNumber} </p>
                            </li>
                            <li>
                              <img src={Icon2} alt="" className="mCS_img_loaded" />
                              <h6 className="contact-title">E-mail Adres</h6>
                              <Link to="#">{item.ContactEmail}</Link><br />
       
                            </li>
                            <li>
                              <img src={Icon3} alt="" className="mCS_img_loaded" />
                              <h6 className="contact-title">Adres</h6>
                              <p>{item.ContactAddress}</p>
                            </li>
                          </ul>
                        ))}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>

            <div className="section-area section-sp2 bg-gray">
              <div className="container wow fadeIn" data-wow-delay="0.8s">
                <div className="heading-bx text-center">
                  <h6 className="title-ext m-b0">Partnerlerİmİz</h6>
                  <h2 className="title-head m-b0">Partnerlerimiz & Müşterilerimiz</h2>
                  <div className="ttr-separator sepimg"></div>
                </div>
                <ClientLogo />
              </div>
            </div>
          </div>
        </div>

        <Footer1 />
      </>
    );
  }
}

export default Faq2;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
import moment from 'moment';

class BlogClassic extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  data: [],
		  imageUrl :'https://megasubdomain.stechomeyazilim.info/',
		};
	  }
	
	  componentDidMount() {
		const apiUrl2 = "https://getjob.stechomeyazilim.info:5101/getBlogEzgican/select/";
	
		fetch(apiUrl2)
		  .then((response) => response.json())
		  .then((data) => {
			this.setState({ data });
		  })
		  .catch((error) => {
			console.error("API'den veri çekme hatası:", error);
		  });

	  }
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Blog</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Blog</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">


								{this.state.data.map((item) => ( // Use this.state.data here
									<div className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.2s">
										<div className="recent-news">
											<div className="action-box">
												<img style={{height:300}} src={this.state.imageUrl+item.BlogImg} alt=""/>
											</div>
											<div className="info-bx">
											
												<h4 className="post-title"><Link to="blog-details-sidebar">{item.BlogTitle}</Link></h4>
												<p>{item.BlogDesc}</p>
												<Link to={{ pathname: '/blog-details-sidebar', state: { _item: item } }} className="btn-link black radius-xl">
        DAHA FAZLA OKU
      </Link>
											</div>
										</div>
									</div>
							    ))}
								</div>
							</div>
						</div>		
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default BlogClassic;
